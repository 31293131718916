<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-row
      v-if="saleData"
      class="print-container"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        ref="downloadInvoice"
        cols="12"
        xl="9"
        md="8"
      >
        <b-card class="mb-1">

          <section
            id="print-invoice-view"
            class="invoice-preview-wrapper"
          >

            <b-row
              class="invoice-preview"
            >
              <b-col
                cols="12"
              >
                <div
                  class="invoice-preview-card"
                >

                  <div class="d-flex justify-content-center bd-highlight">
                    <div class="bd-highlight text-center">
                      <img
                        v-if="configs.business_logo"
                        :src="configs.business_logo"
                        width="64"
                      >
                      <h3 class="mb-0 mt-1">
                        {{ configs.business_name }}
                      </h3>
                      <p class="mb-1">
                        {{ configs.business_description }}
                      </p>
                      <p class="mb-1">
                        {{ configs.business_address }} {{ configs.business_phone }}
                      </p>
                    </div>
                  </div>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <div class="d-flex bd-highlight">
                    <div class="mr-auto pb-1 bd-highlight">
                      <span class="text-dark font-weight-bold">{{ $t('Invoice') }}: </span>#{{ saleData.id }}
                    </div>
                    <div class="pb-1 bd-highlight">
                      <span class="float-right"><span class="text-dark font-weight-bold">{{ $t('Date') }}: </span>{{ toLocaleDate(saleData.sale_date) }}</span>
                    </div>
                  </div>

                  <div class="d-flex bd-highlight border-bottom">
                    <div class="mr-auto pb-1 bd-highlight">
                      <span class="text-dark font-weight-bold">{{ $t('Client') }}: </span>{{ saleData.client.name }}&nbsp;
                      [{{ saleData.client.phone }} - {{ saleData.client.address }}]
                    </div>
                    <div class="pb-1 bd-highlight">
                      <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(saleData.status)}`"
                        class="text-capitalize"
                      >
                        {{ resolveStatus(saleData.status) }}
                      </b-badge>
                    </div>
                  </div>
                  <!-- Invoice Description: Table -->
                  <b-table-lite
                    class="mt-1"
                    hover
                    bordered
                    :items="saleData.sale_items"
                    :fields="[{ label: '', key: 'sr'}, { label: $t('Item'), key: 'item'}, { label: $t('Price'), key: 'price', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Qty'), key: 'quantity', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Amt'), key: 'amount', tdClass: 'text-right', thClass: 'text-right'}]"
                    :small="isSmallScreen"
                  >
                    <!-- Column: Price -->
                    <template #cell(price)="data">
                      {{ data.item.price.toLocaleString() }}
                    </template>

                    <!-- Column: Price -->
                    <template #cell(quantity)="data">
                      {{ data.item.quantity.toLocaleString() + ' ' + data.item.unit }}
                    </template>

                    <!-- Column: Amount -->
                    <template #cell(amount)="data">
                      {{ data.item.amount.toLocaleString() }}
                    </template>

                  </b-table-lite>

                  <div class="d-flex flex-row-reverse bd-highlight mb-0-75 mt-1">
                    <div
                      class="bd-highlight w-120px text-right text-dark"
                      style="padding-right: 8px;"
                    >
                      {{ saleData.total_amount.toLocaleString() }}
                    </div>
                    <div class="bd-highlight pl-1 w-180px text-right text-dark">
                      {{ $t('Total') }}&nbsp;:&nbsp;
                    </div>
                  </div>

                  <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                    <div
                      class="bd-highlight w-120px text-right text-dark"
                      style="padding-right: 8px;"
                    >
                      {{ saleData.discount.toLocaleString() }}
                    </div>
                    <div class="bd-highlight w-180px pl-1 text-right text-dark">
                      {{ $t('Discount') }}&nbsp;:&nbsp;
                    </div>
                  </div>

                  <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                    <div
                      class="bd-highlight w-120px text-right font-weight-bold pt-0-75 border-top text-dark"
                      style="padding-right: 8px;"
                    >
                      {{ saleData.net_amount.toLocaleString() }}
                    </div>
                    <div class="bd-highlight w-180px pl-1 pt-0-75 border-top text-right text-dark">
                      {{ $t('Net') }}&nbsp;:&nbsp;
                    </div>
                  </div>

                </div>
              </b-col>
            </b-row>
            <div
              id="timestamp"
              style="display:none; font-size: small; margin-top: 15px;"
              class="text-center"
            >
              <div>Invoice is downloaded at {{ downloadAt }}</div>
              <i>tallysale.com - Free POS for Retail & Wholesale Shops</i>
            </div>
          </section>

        </b-card>

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        xl="9"
        md="8"
        class="invoice-actions"
      >
        <b-card>
          <div
            class="d-flex flex-row-reverse"
          >

            <!-- Button: Close -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="gradient-primary"
              :class="{'col-sm-6': isMobile}"
              pill
              @click="close"
            >
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>

            <!-- Button: Share -->
            <b-button
              v-if="canShare"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="gradient-success"
              class="mr-1"
              pill
              @click="shareImage"
            >
              <feather-icon
                icon="Share2Icon"
              />
            </b-button>

            <!-- Button: Download Slip -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mr-1"
              pill
              @click="downloadAsImage"
            >
              <feather-icon
                icon="DownloadIcon"
              />
            </b-button>

            <!-- Button: Print Slip -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-secondary"
              class="mr-1"
              pill
              @click="printSlip"
            >
              <feather-icon
                icon="PrinterIcon"
              />
            </b-button>

          </div>

        </b-card>
      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BButton, BTableLite, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import domtoimage from 'dom-to-image'
import storeModule from '../../../common/storeModule'
import saleStoreModule from '../saleStoreModule'
import configData from '../../../common/configModule'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BTableLite,
    // BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isMobile: false,
      downloadAt: '',
    }
  },
  computed: {
    canShare() {
      // Check if the Web Share API supports sharing files
      return navigator.canShare && navigator.canShare({ files: [new File([''], 'dummy.png', { type: 'image/png' })] })
    },
  },
  mounted() {
    this.updateScreenWidth()
    window.addEventListener('resize', this.updateScreenWidth)
  },
  methods: {
    toLocaleDate(dateStr) {
      // Check if the input string is a valid date
      const dateObj = new Date(dateStr)
    if (Number.isNaN(dateObj)) {
        throw new Error('Invalid date format')
    }

    // Extract day, month, and year from the date object
    const day = dateObj.getDate().toString().padStart(2, '0') // Pads with zero if necessary
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
    const year = dateObj.getFullYear()

    // Format and return the date in dd/mm/yyyy format
    return `${day}/${month}/${year}`
    },
    downloadAsImage() {
      const element = this.$refs.downloadInvoice
      this.downloadAt = (new Date()).toLocaleString('en-GB', { hour12: true })
      document.getElementById('timestamp').style.display = 'block'

      domtoimage.toPng(element)
        .then(dataUrl => {
          // Create an image
          const img = new Image()
          img.src = dataUrl

          // Create a link and set the image URL as the href
          const link = document.createElement('a')
          link.download = 'captured-content.png'
          link.download = `invoice-${this.saleData.id}-${this.saleData.client.name}.png`
          link.href = dataUrl
          link.click()

          document.getElementById('timestamp').style.display = 'none'

          // Optional: append the image to the body to see it on the page
          // document.body.appendChild(img);
        })
        .catch(error => {
          console.log('Something went wrong!', error)
        })
    },
    shareImage() {
      const element = this.$refs.downloadInvoice
      this.captureImage(element, async dataUrl => {
        try {
          const blob = await fetch(dataUrl).then(res => res.blob())
          const filesArray = [new File([blob], `invoice-${this.saleData.id}-${this.saleData.client.name}.png`, { type: 'image/png' })]
          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            await navigator.share({
              files: filesArray,
              title: 'Shared Image',
              text: 'Here is an image I wanted to share with you.',
            })
            console.log('Image shared successfully')
          } else {
            console.log('This device does not support sharing files.')
          }
        } catch (error) {
          console.log('Error sharing the image', error)
        }
      })
    },
    captureImage(element, callback) {
      this.downloadAt = (new Date()).toLocaleString('en-GB', { hour12: true })
      document.getElementById('timestamp').style.display = 'block'

      domtoimage.toPng(element)
        .then(dataUrl => {
          document.getElementById('timestamp').style.display = 'none'
          callback(dataUrl)
        })
        .catch(error => {
          console.log('Something went wrong when capturing the image!', error)
        })
    },
    updateScreenWidth() {
      this.isMobile = window.innerWidth <= 768 || window.innerWidth <= 1024 // 768px is a common breakpoint for 'sm' screens
    },
    // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
    resolveStatus(status) {
      if (status === 1) return this.$t('Unpaid')
      if (status === 2) return this.$t('Partially Paid')
      return this.$t('Paid')
    },
    close() {
      this.$router.push({ name: 'pos-sale-new' })
    },
    printSlip() {
      // for printing of slip 80mm printer
      // Get HTML to print from element
      // <img style="margin:0; margin-bottom: 5px;" src="${this.configs.business_logo}" width="72">
      const prtHtmlStart = `
        <div id="sub-header" style="text-align:center;width:100%;">
          <h4 style="margin:0;">
            ${this.configs.business_name}
          </h4>
          <p style="font-size: 13px;margin:5px;">
            ${this.configs.business_address}, ${this.configs.business_phone}, ${this.configs.business_website}
          </p>
        </div>
        <hr>
        <div id="sub-header" style="font-size:12px;">
          <table style="width:100%;">
            <tr>
              <td style="text-align:left;">${this.$t('Invoice')}: ${this.saleData.id}</td>
              <td style="text-align:right;">${this.$t('Date')}: ${this.saleData.sale_date}</td>
            </tr>
            <tr>
              <td colspan=2 style="text-align:left;">${this.$t('Client')}: ${this.saleData.client.name} [${this.saleData.client.phone} - ${this.saleData.client.address}]</td>
            </tr>
          </table>
        </div>
        <hr>
        <div style="font-size:12px;">
          <table style="width:100%;">
            <tr>
              <th>#</th>
              <th style="text-align:left;">${this.$t('Item')}</th>
              <th style="text-align:right;">${this.$t('Price')}</th>
              <th colspan=2 style="text-align:right;">${this.$t('Qty')}</th>
              <th style="text-align:right;">${this.$t('Amt')}</th>
            </tr>
      `

      const prtHtmlEnd = `
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Total')}&nbsp;:&nbsp;</td>
            <td style="text-align:right;">${this.saleData.total_amount.toLocaleString()}</td>
          </tr>
          <tr style="border-bottom: 1px solid black;">
            <td colspan=5 style="text-align:right;">${this.$t('Discount')}&nbsp;:&nbsp;</td>
            <td style="text-align:right;border-bottom: 1px solid black;">${this.saleData.discount.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Net')}&nbsp;:&nbsp;</td>
            <td style="text-align:right;">${this.saleData.net_amount.toLocaleString()}</td>
          </tr>
        </table>
        </div>
        <div style="text-align:center;padding-bottom:10px;padding-top:5px;font-size:10px;">
          tallysale.com
        </div>
      `
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1">
        </head>
        <body style="padding:10px;">
          ${prtHtmlStart}
          ${this.saleData.sale_items.map(this.itemTemplate).join('')}
          ${prtHtmlEnd}
        </body>
      </html>`)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.setTimeout(() => { WinPrint.close() }, 50000)
    },
    itemTemplate(item) {
      return `
          <tr>
            <td>${item.sr}</td>
            <td>${item.item}</td>
            <td style="text-align:right;">${item.price.toLocaleString()}</td>
            <td colspan=2 style="text-align:right;">${item.quantity}</td>
            <td style="text-align:right;">${item.amount.toLocaleString()}</td>
          </tr>
      `
    },
  },
  setup(props, context) {
    const currentBreakpoint = computed(() => store.getters['app/currentBreakPoint'])
    const isSmallScreen = (currentBreakpoint.value === 'sm' || currentBreakpoint.value === 'xs')
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const saleData = ref({
      sale_date: null,
      client: [],
      client_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_receipt: 0,
      employee_name: '',
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      sale_items: [],
      sale_receipts: [],
    })
    const router = context.root.$router
    const saleId = router.currentRoute.params.id

    store.dispatch('sale/fetchOne', { id: saleId })
      .then(response => {
        const dataItems = []
        const dataReceipts = []
        const responseItems = response.data.data.sale_items
        const responseReceipts = response.data.data.sale_receipts
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            sr: (i + 1),
            item_id: responseItems[i].item_id,
            item: `${responseItems[i].item.sku} ${responseItems[i].item.name}`,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            unit: responseItems[i].item.unit,
            amount: responseItems[i].amount,
          })
        }
        for (let i = 0; i < responseReceipts.length; i += 1) {
          dataReceipts.push({
            sr: (i + 1),
            receipt_date: responseReceipts[i].receipt_date,
            amount: responseReceipts[i].amount,
            reference: responseReceipts[i].reference,
          })
        }

        const responseData = {
          id: response.data.data.id,
          sale_date: response.data.data.sale_date,
          client: response.data.data.client,
          client_id: response.data.data.client_id,
          total_amount: response.data.data.total_amount,
          discount: response.data.data.discount,
          net_amount: response.data.data.net_amount,
          total_receipt: response.data.data.total_receipt,
          employee_name: response.data.data.employee_name,
          reference: response.data.data.reference,
          remark: response.data.data.remark,
          branch_name: response.data.data.branch_name,
          status: response.data.data.status,
          sale_items: dataItems,
          sale_receipts: dataReceipts,
        }
        saleData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          saleData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    const resolveStatusVariant = status => {
      if (status === 1) return 'danger'
      if (status === 2) return 'warning'
      if (status === 3) return 'success'
      if (status === 4) return 'secondary'
      return 'primary'
    }
    const { configs } = configData()

    return {
      isSmallScreen,
      saleData,
      printInvoice,
      configs,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.table th, .table td {
  vertical-align: top;
}
.text-dark {
  color: #000000;
}
.w-120px {
  width: 120px;
}
.w-180px {
  width: 180px;
}
.pr-2rem {
  padding-right: 2rem !important;
}
.tabStyle {
  color : black !important;
}
[dir] .nav-tabs .nav-link {
  padding-left: 0;
}
.mb-0-75 {
  margin-bottom: 0.75rem !important;
}
.pt-0-75 {
  padding-top: 0.75rem !important;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body, h3 {
    background-color: transparent !important;
    font-size: 27px;
    font-family: 'Pyidaungsu' !important;
    color: #000000 !important;
  }
  p {
    line-height: 2.5rem !important;
  }
  img {
    width: 164px;
  }
  .table thead th, .table tfoot th {
    font-size: 27px;
  }
  .w-120px {
    width: 160px;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #000 !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pr-2rem {
    padding-right: 5px !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .print-container {
    > [class*="col-"] {
      flex: 0 0 100%;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  [dir] .card {
    border: none !important;
  }
  [dir] .card-body {
    padding: 0;
  }
  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
